@import "//at.alicdn.com/t/font_1941350_h1bkay939hc.css";

:global(.anticon) {
    &::before {
        font-family: "anticon", "anticon-docpark" !important;
        display: inline-block;
    }
}

@headerHeight: 50px;
@transitionDuration: 300ms;
@layout-header-background: #E9EBF4;
@primary-color: #000000;
@menu-selected-background-color: #E9EBF4; //选中的背景颜色 1e282c
@menu-selected-color: #4264EC; //选中的字体颜色

.side {
    position: fixed;
    z-index: 999;
    top: @headerHeight;
    left: 0;
    bottom: 0;
    width: 200px;
    box-shadow: 0 4px 4px 2px #ddd;
    background: #ffffff; //#2c3b41;
    transition: all @transitionDuration;

    .outer {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all @transitionDuration;
    }

    .inner {
        position: relative;
        height: 100%;
        overflow-x: visible;
        overflow-y: scroll;
        transition: all @transitionDuration;
        -webkit-overflow-scrolling: touch;
    }

    :global {
        .ant-menu-item-selected {
            z-index: 1;
        }
        .ant-menu-inline-collapsed > .ant-menu-item,
        .ant-menu-inline-collapsed
            > .ant-menu-submenu
            > .ant-menu-submenu-title {
            left: 0;
            padding: 0px 5px 0px 15px !important;
            text-overflow: clip;
        }
        .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
            height: 44px;
            line-height: 44px;
            padding-left: 18px !important;
        }
        .ant-menu-inline-collapsed {
            width: 50px;
        }

        .ant-menu-submenu-inline > .ant-menu-inline {
            background-color: #F5F5F5;
        }

        .ant-menu-submenu-inline
            > .ant-menu-submenu-title
            .ant-menu-submenu-arrow {
            right: 6px;
            width: 20px;
        }

        .ant-menu-submenu-popup {
            //background: #666;
            background: @primary-color !important;
        }

        .ant-menu-inline .ant-menu-submenu-title {
            height: 30px;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 0 16px;
            overflow: hidden;
            font-size: 14px;
            line-height: 44px;
            text-overflow: ellipsis;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active,
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: @menu-selected-background-color !important;
        }

        .ant-menu-item:hover,
        .ant-menu-item-active,
        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: @menu-selected-color;
        }

        .ant-menu-submenu-vertical
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:after,
        .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:after,
        .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:after,
        .ant-menu-submenu-inline
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:after,
        .ant-menu-submenu-vertical
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-vertical-left
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-vertical-right
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-inline
            > .ant-menu-submenu-title:hover
            .ant-menu-submenu-arrow:before {
            background: linear-gradient(
                to right,
                @menu-selected-color,
                @menu-selected-color
            );
        }
    }

    .drag-bar {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 100%;
        cursor: col-resize;
        z-index: 1;
    }

    .drag-bar-icon {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        right: -9px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 20px 0;
        width: 6px;
        height: 100px;
        //background: #fff;
        //border: 1px solid #e8e8e8;
        border-left: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: col-resize;

        span {
            display: block;
            width: 2px;
            height: 2px;
            background: #bbb;
            border-radius: 50%;
        }
    }
}

@primary-color: #3c8dbc;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;