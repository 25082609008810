@menu-background-color: #ffffff; // #2c3b41; //菜单背景颜色
@menu-color: #000000; //字体颜色
@menu-selected-background-color: #E9EBF4; //选中的背景颜色 #1e282c;
@menu-selected-color: #2F54EB; //选中的字体颜色
@menu-Selected-left-color: #3c8dbc; //选中之后左边的颜色

:global(.ant-tooltip-inner .font-icon) {
    margin-right: 8px;
}

.side-menu {
    height: 100%;
    padding-right: 1px;

    & > ul {
        height: 100%;
    }

    :global {
        .font-icon {
            margin-right: 8px;
        }

        .ant-menu {
            border-right: 0;
            background-color: @menu-background-color;
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .ant-menu-item {
            height: 44px;
            line-height: 44px;
            margin-bottom: 0px !important;
            padding-left: 18px !important;
        }

        .ant-menu-item > a {
            color: @menu-color;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: @menu-selected-background-color;
        }

        .ant-menu-item-selected > a {
            color: @menu-selected-color;
            // background: @menu-selected-background-color;
        }

        .ant-menu-item:hover {
            background: @menu-selected-background-color;
        }
        .ant-menu-item > a:hover {
            color: @menu-selected-color;
        }

        .ant-menu-item::after {
            display: none;
        }

        .ant-menu-item.ant-menu-item-selected {
            border-left-color: @menu-Selected-left-color;
            &::after {
                display: block;
                right: 0px;
                border-right: 3px solid #2F54EB!important;
            }
        }

        .ant-menu-submenu-title {
            color: @menu-color;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
        }

        .ant-menu-submenu-selected {
            color: @menu-selected-color;
        }
        .ant-menu-submenu-selected > i {
            color: @menu-color;
        }
        .ant-menu-submenu-title:hover {
            background: @menu-selected-background-color;
        }

        .ant-menu-sub.ant-menu-inline > .ant-menu-item,
        .ant-menu-sub.ant-menu-inline
            > .ant-menu-submenu
            > .ant-menu-submenu-title {
            list-style-position: inside;
            list-style-type: disc;
            padding-left: 32px !important;
        }
        // .ant-menu-submenu-selected .ant-menu-submenu-title {
        //     color: @menu-selected-color;
        //     border-left-color: @menu-Selected-left-color;
        //     border-left: 1px solid;
        //     &::after {
        //         display: block;
        //         left: 0;
        //         right: auto;
        //     }
        // }
    }

    .menu-text {
        transition: all 300ms;
    }
}

@primary-color: #3c8dbc;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;