.page-content-root {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
}

.page-content {
    flex: 1;
    margin: 8px;
    padding: 8px;
    background: #fff;
    margin-left:20px;
    margin-right: 20px;
}

.footer {
    margin-bottom: 16px;
}

.page-loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: 99999;
    cursor: not-allowed;
    text-align: center;

    :global(.ant-spin) {
        position: absolute;
        top: 30%;
    }
}

@primary-color: #3c8dbc;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;