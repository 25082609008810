@headerHeight: 50px;
@transitionDuration: 300ms;
@layout-header-background: #001529;
@primary-color: #1890ff;
@logo-container-color:#367fa9;

.header {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw; // 不会因为body出现滚动条而抖动
    height: @headerHeight;
    padding-right: 16px;
    background-color: @primary-color;
    // background-image: url('./topbg.png');
    background-position: right;
    background-repeat: no-repeat;

    .logo-container {
        display: inline-flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        height: @headerHeight;
        position: relative;
        overflow: hidden;
        background: @logo-container-color;
        //background: linear-gradient(to right, darken(@primary-color, 5%), @primary-color) !important;
        box-shadow: none;

        h1 {
            color: #fff;
        }
    }

    .trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        transition: all .3s;
        padding: 0 5px;
        color: #fff;

        &:hover {
            opacity: 0.5;
        }
    }

    .center {
        flex-shrink: 1;
        flex-grow: 1;
        overflow: auto;

        :global {
            .ant-menu {
                background: none;
            }

            .ant-breadcrumb a,
            .ant-breadcrumb .ant-breadcrumb-link,
            .ant-breadcrumb .ant-breadcrumb-separator {
                color: #fff;
            }
        }

    }



    .right {
        display: flex;
        align-items: stretch;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;
        
        .action {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            padding: 0 6px;
            transition: all @transitionDuration;
            height: 100%;
            color: #fff;
            i {
                font-size: 16px;
                vertical-align: middle;
                margin-right: 5px;
            }
            &:global(.ant-popover-open),
            &:hover {
                background: rgba(0,0,0,0.1);
                color: #f6f6f6;
            }
            
        }

        .button{
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            padding: 0 6px;
            transition: all @transitionDuration;
            height: 100%;
            color: #fff;
            .container{
               border-radius: 5px;
               padding:4px 4px;
            }
            &:hover {
                background: rgba(0,0,0,0.1);
                color: #f6f6f6;
            }
            
        }

    }

    &[data-theme=dark] {
        background: @layout-header-background;
        box-shadow: 2px 0 6px lighten(@layout-header-background, 10%);

        .logo {
            background: @layout-header-background;
            box-shadow: none;
        }

        .right {
            color: #fff;
        }

        .action {
            &:global(.ant-popover-open),
            &:hover {
                background: lighten(@layout-header-background, 10%) !important;
            }
            
        }
    }
}

@primary-color: #3c8dbc;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;