/*
全局样式，不要轻易编写全局样式
*/
@import "//at.alicdn.com/t/font_1941350_h1bkay939hc.css";
html,
body,
#root {
  height: 100%;
}
@page {
  size: auto;
  margin: 0mm;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif, "Helvetica", "PingFang SC", "Microsoft YaHei", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .ant-menu-item > a {
    color: #fff !important;
} */
#nprogress .bar {
  z-index: 9999;
}
.ant-table-thead {
  background-color: #ffffff !important;
}
:global(.anticon)::before {
  font-family: "anticon", "anticon-docpark" !important;
  display: inline-block;
}
.ant-form label,
.ant-form-explain {
  font-size: 12px;
}
/*.ant-form-item-label,*/
/*.ant-form-item-control {*/
/*line-height: 25px;*/
/*}*/
/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/
/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/
/* 只在打印时显示 */
.just-print {
  display: none !important;
}
@media print {
  body {
    padding: 0 !important;
    background: none;
  }
  /* 打印时不显示 */
  .no-print {
    display: none !important;
  }
  .just-print {
    display: block !important;
  }
  .ant-message {
    display: none !important;
  }
  .ant-modal-mask {
    display: none !important;
  }
}
/**滚动条*/
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c2c2c2;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: rgba(250, 250, 250, 0.9);
}
.flex_layout_contianer {
  flex: 1;
  overflow: hidden;
}
.common_layout_container {
  position: "absolute";
  top: "50px";
  left: 0;
  height: "calc(100% - 74px)";
  width: "100%";
  overflow: "hidden";
}
.common_ant_modal {
  height: 600px;
  overflow: auto;
}
/* .ant-modal-body {
    height: 500px;
    overflow: auto;
} */
/* .ant-table-body {
    min-height: 420px;
} */
.flod_icon {
  position: absolute;
  height: 50px;
  width: 12px;
  line-height: 50px;
  top: 35%;
  border: 1px solid #e6e9ed;
  border-left: 0;
  border-radius: 0 40% 40% 0;
  z-index: 1;
}
.more_query {
  overflow: hidden;
  transition: all 0.6s;
}
.header_layout {
  margin-bottom: 12px;
}
.content_layout {
  background-color: #fff;
  flex: 1;
}
.sider_layout {
  margin-right: 12px;
}
.common_color {
  background-color: #eff2f5 !important;
}
.ant-tabs-content {
  height: calc(100% - 190px);
}
/* .ant-tabs-top-content > .ant-tabs-tabpane {
    flex-shrink: 1 !important;
} */
.print_hide {
  visibility: hidden !important;
  display: none !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 12px 12px!important;
  overflow-wrap: break-word;
  height: 100%;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 0px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 0px;
}
.ant-table-placeholder,
.ant-card-head {
  border-bottom: 0px;
}
.ant-form label,
.ant-form-explain {
  font-size: 14px;
}
.ant-tree-node-selected {
  width: 90%;
}
.ant-tree-node-selected .ant-tree-title {
  color: #738BF1;
  display: inline-block;
  width: 100%;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(47, 84, 235, 0.06) !important;
}
.ant-tabs-bar,
.ant-form {
  background-color: #ffffff;
}
.ant-card-head-title {
  padding-bottom: 0px!important;
}
.ant-card-head {
  border-bottom: 0px!important;
}
.ant-form-item-label {
  text-align: right!important;
}
.line-btn:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1;
  /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}
.report-setting {
  margin-right: 0px;
}
.ant-tabs-nav-container {
  padding: 0 16px;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-y: hidden;
}
.ant-layout {
  overflow: hidden;
}
.ant-card,
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%!important;
}
.layoutMargin {
  margin: 0 16px 8px 16px!important;
}
.layoutMargin .ant-card-body {
  padding: 10px!important;
}
