@default-color: #fff;
@active-color: #f0f2f5;
//@active-color: #fff;
@primary-color: #1890ff;
@border-color: #e8e8e8;
@corner-size: 10px;

.draggable-tabs-bar-root {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    overflow: hidden;
    background: @default-color;

    &.sorting,
    &.sorting * {
        cursor: move !important;
    }
}

body > .draggable-tabs-bar-horizontal-item {
    z-index: 9999;
    transition: 0ms;
}

.draggable-tabs-bar-horizontal-item {
    position: relative;
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    user-select: none;
    font-weight: 400;

    &:hover,
    &.active {
        position: relative;

        .draggable-tabs-bar-wrapper {
            position: relative;
            background: @active-color;
            color: #000;
            font-weight: bolder;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .item-inner {
            //border-bottom: 2px solid @primary-color;
        }

        .tabs-bar-left-corner,
        .tabs-bar-right-corner {
            position: absolute;
            bottom: 0;
            left: -@corner-size + 1px;
            width: @corner-size;
            height: @corner-size;
            z-index: 10;
            overflow: hidden;
        }

        .tabs-bar-left-corner:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-bottom-right-radius: @corner-size;
            box-shadow: @corner-size 0 0 @corner-size @active-color;
        }

        .tabs-bar-right-corner {
            left: auto;
            right: -@corner-size + 3px;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                border-bottom-left-radius: @corner-size;
                box-shadow: -@corner-size 0 0 @corner-size @active-color;
            }
        }

        .draggable-tabs-bar-wrapper {
            &:before {
                display: none;
            }

            &:after {
                box-shadow: 0 0 10px 20px @active-color;
            }
        }

        & + .draggable-tabs-bar-horizontal-item {
            .draggable-tabs-bar-wrapper:before {
                display: none;
            }
        }
    }

    &:hover {
        .close-wrapper {
            opacity: 1;
        }
    }

    &:first-child {
        .draggable-tabs-bar-wrapper:before {
            display: none;
        }
    }

    .draggable-tabs-bar-horizontal-item-inner {
        display: flex;
        align-items: center;
        height: 100%;
        transition: width 300ms;
        box-sizing: border-box;
        //padding-right: 2px;
        overflow: hidden;
    }

    .draggable-tabs-bar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 100%;
        width: 100%;
        padding: 0 14px;
        //border-top-left-radius: @corner-size/2;
        //border-top-right-radius: @corner-size/2;
        background-color: @default-color;
        color: #333;
        transition: 300ms;
        //border-right: 1px solid @border-color;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            bottom: 5px;
            left: 0;
            width: 1px;
            border-left: 1px solid @border-color;
            z-index: 10;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -2px;
            width: 0;
            box-shadow: 0 0 10px 20px @default-color;
        }
    }

    .item-inner {
        display: flex;
        //justify-content: center;
        flex-grow: 1;
        align-items: center;
        position: relative;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 12px;
        border-bottom: 2px solid transparent;
    }

    .close-wrapper {
        position: absolute;
        right: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -1px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        transform: scale(.8); // 最小字体只能是12
        cursor: pointer;
        opacity: 0;
        z-index: 1;
        transition: 300ms;

        &:hover {
            background: #d9d9d9;
        }
    }
}

@primary-color: #3c8dbc;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;